import { Link } from "gatsby"
import React from "react"

const Hero = ({ className, children }) => (
  <section className={'hero__cont ' + className}>
    {children}
  </section>
)


export default Hero



