import { Link } from "gatsby"
import React from "react"
import Nav from './nav'


const Footer = () => (
  <footer className="foot__cont foot--main">
    <div className="foot__content">
      <div className="cont--spaced">
        <div className="cont__row">
          <div className="col col--5">
            <div className="foot__title">Navigation</div>
            <Nav className="nav--foot" />
          </div>
          <div className="col col--5">
            <div className="foot__title">Social</div>
            <div className="foot__text">Follow us on social media for the latest updates from <span className="typ--strong">PrimeSet</span>.</div>
            <div className="foot__icons">
              {/* <a href="" className="foot__icons__icon"><span className="i i-gplus"></span></a> */}
              <a href="https://twitter.com/theprimeset" target="_blank" className="foot__icons__icon"><span className="i i-twitt"></span></a>
              {/* <a href="" className="foot__icons__icon"><span className="i i-facbk"></span></a> */}
            </div>
          </div>
          <div className="col col--5">
            <div className="foot__title">Subscribe</div>

            <form className="form__cont form--foot"  name="subscribe" method="POST" data-netlify="true">
              <input type="hidden" name="form-name" value="subscribe" />
              <div className="input input--lg input--btn">
                <input type="text" placeholder="Email Address" name="Email" />
                <button className="input--btn__btn btn btn--prim" type="submit" data-hover="Subscribe">Subscribe</button>
              </div>
              <span className="input__note">Subscribe to our latest news!</span>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div className="foot__foot">
      <div className="foot__foot__content">
        <div className="cont--spaced">
          Built with <span className="typ--prim">♥</span> by <span className="typ--strong"> PrimeSet</span>, ©{new Date().getFullYear()}.
        </div>
      </div>
    </div>

     
    <a data-hover="Top" className="btn btn--text btn--it btn--top"  href="#top">
      <span className="btn__text">Top</span>
      <span className="i i-arw-t"></span>
    </a>
  </footer>
)

export default Footer
