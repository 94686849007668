import { Link } from "gatsby"
import React from "react"

const Nav = ({ className }) => (
  <div className={'nav__cont ' + className}>
    <Link to="/" className="nav__anch" activeClassName="nav__anch--active">
      Home
    </Link>
    <Link to="/group" className="nav__anch" activeClassName="nav__anch--active">
      Group
    </Link>
    <Link to="/careers" className="nav__anch" activeClassName="nav__anch--active">
      Careers
    </Link>
    <Link to="/contact" className="nav__anch" activeClassName="nav__anch--active">
      contact
    </Link>
  </div>
)




export default Nav



