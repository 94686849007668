
import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"


// import Header from "./common/header" // TODO: Incorproate this correctly
import "../stylus/style.styl"
import Footer from "./common/footer"
import Nav from "./common/nav"

import Btn from "./common/button"




export default class Index extends React.Component<{data, children, propTypes, props}>  {
  state = {isToggleOn: false};

  handleClick = () => {
    this.setState({isToggleOn: !this.state.isToggleOn})
  }
  
  render() {
    const { children, data, props } = this.props;
    
    return (
      <section className="cont cont--main" id="top">

        <button onClick={this.handleClick} className={"nav--toggle" + (this.state.isToggleOn ? " nav--toggle--active" : '')}>
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div className={"nav--mobile" + (this.state.isToggleOn ? " nav--mobile--active" : '')}>
          <Nav className=""/>
        </div>
        <PageHead />
        {/* <Header className="werwer" siteTitle={data.site.siteMetadata.title} /> */}

        {children}

        <Footer />
       
      </section>
    )
  }
}


const PageHead = () => (
  <Helmet> 
    {/* TODO: Make head file that can be imported everywhere that has a new page creation */}
    <link href="https://fonts.googleapis.com/css?family=Lato:300,400i|Space+Mono:400,700|BioRhyme:300,700&display=swap" rel="stylesheet"/>
  </Helmet> 
)

Index.propTypes = {
  children: PropTypes.node.isRequired,
}

export const Layout = (props) => graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
